<template>
    <div class="col-md-3 col-sm-4 col-6 m-b-15">
        <div class="product-card">
            <div class="badge" v-if="hot">Hot</div>
            <div class="product-thumb">
                <img :src="prefix + item.image" :alt="item.title" v-if="item.image">
                <img :src='prefix + logo' style="height: 40px; margin-top: 15px"
                             v-else-if="logo">
            <img alt="Xeoscript" src="../../assets/img/logo-white.svg" v-if="!item.image && !logo">
            </div>
            <div class="product-details">
                <span class="product-category" v-if="item.category" v-html="item.category"></span>
                <h4 v-if="item.title"><a href="" v-html="item.title"></a></h4>

                <div class="product-bottom-details">
                    <div class="product-price">
                        <small class="small" v-if="item.mrp !== item.price">
                            ₹ <Currency ref="currencyElement" :value="item.mrp"></Currency>
                        </small>
                        <strong v-if="item.price">
                            ₹ <Currency ref="currencyElement" :value="item.price"></Currency>
                        </strong>
                    </div>
<!--                    <div class="product-links" v-if="item.count>0">-->
<!--                        <a href="" @click.prevent="item.count -= 1">-</a>-->
<!--                        <span v-html="item.count"></span>-->
<!--                        <a href="" @click.prevent="item.count += 1">+</a>-->
<!--                    </div>-->
<!--                    <div class="product-links" v-else>-->
<!--                        <a class="home-add" href="" @click.prevent="item.count += 1">Add</a>-->
<!--                    </div>-->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import urls from '@/data/urls';
import Currency from '@components/Currency';

export default {
    name: 'ProductCard',
    props: {
        item: {
            required: true,
            type: Object
        },
        hot: {
            required: false,
            type: Boolean,
            default: false
        },
        logo: {
            type: String
        }
    },
    data () {
        return {
            prefix: urls.baseUrl
        };
    },
    components: { Currency }
};
</script>
<style lang="scss">
    $primary: #fbb72c;

    .product-container {
        padding-right: 15px;
        padding-left: 15px;
        width: 50%;
        float: left;
    }

    .m-b-15 {
        margin-bottom: 15px;
    }

    .product-card {
        position: relative;
        box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
        /*margin: 10px 10px;*/
        background: #fafafa;
        border-radius: 5px;
        transition: all 0.3s;
        /*width: 50%;*/
        /*float: left;*/

        &:hover {
            box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.3);
        }

        .badge {
            position: absolute;
            left: 0;
            top: 20px;
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 700;
            background: red;
            color: #fff;
            padding: 3px 10px;
        }

        .product-thumb {
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            max-height: 200px;
            padding: 20px 20px;
            background: #f0f0f0;

            img {
                width: 100px;
                height: 100px;
                max-width: 100%;
                max-height: 100%;
                transition: all 0.4s;

                &:hover {
                    transform: translateY(-10px);
                }
            }
        }

        .product-details {
            padding: 15px;

            .product-category {
                display: block;
                font-size: 12px;
                font-weight: 700;
                text-transform: uppercase;
                color: #ccc;
                margin-bottom: 18px;
            }

            h4 {
                margin: 0;

                a {
                    font-weight: 500;
                    display: block;
                    margin-bottom: 15px;
                    text-transform: uppercase;
                    color: #363636;
                    text-decoration: none;
                    transition: 0.3s;
                    font-size: 14px;
                    overflow: hidden;
                    max-height: 15px;
                    word-break: break-all;

                    &:hover {
                        color: $primary;
                    }
                }
            }

            p {
                font-size: 15px;
                line-height: 22px;
                margin-bottom: 18px;
                color: #999;
            }

            .product-bottom-details {
                overflow: hidden;
                border-top: 1px solid #eee;
                padding-top: 20px;

                div {
                    float: left;
                    width: 100%;
                }

                .product-price {
                    font-size: 18px;
                    color: $primary;
                    font-weight: 600;

                    .small {
                        font-size: 80%;
                        font-weight: 400;
                        text-decoration: line-through;
                        display: inline-block;
                        margin-right: 40px;
                    }
                }

                .product-links {
                    text-align: right;

                    span {
                        padding: 5px 10px;
                    }

                    a {
                        display: inline-block;
                        margin-left: 5px;
                        color: #fff;
                        transition: all 0.3s;
                        border-radius: 15px;
                        background-color: $primary;
                            padding: 2px 8px;
                        font-size: 0.7em;
                        text-decoration: none;

                        &a:hover {
                            color: white;
                        }

                    }
                }
            }
        }
    }

    .home-add {
        padding: 2px 17px !important;
    }

</style>
