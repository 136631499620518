<template>
    <div class="container">

        <nav class="navigation" style="text-align: center">
            <img :src='prefix + bg_image' style="height: 40px; margin-top: 15px"
                             width="96" v-if="bg_image">
            <img alt="Xeoscript" src="../../assets/img/logo-white.svg" style="height: 40px; margin-top: 15px" v-if="!bg_image">
        </nav>

        <div class="search-box" v-show="!showCart">
            <b-form-input type="text" class="com-md-12 input" placeholder="Search" v-model="searchTerm"></b-form-input>
        </div>
        <div class="scroll">
            <transition-group tag="div" name="list" v-show="!showCart" class="row">
                <ProductCard :item="item" v-for="item in filteredData" :key="item.id"/>
            </transition-group>
        </div>

<!--        <footer>-->
<!--            <div class="main-home" v-show="!showCart">-->
<!--                <div class="style clearfix">-->

<!--                    <div class="info-box-home col-6" style="text-align: left; margin-top: 5px;">-->
<!--                        <h6 style="font-size: 1.25rem;">Total:-->
<!--                            <Currency ref="currencyElement" :value="selectedPrice"></Currency>-->
<!--                        </h6>-->
<!--                    </div>-->

<!--                    <div class="card-input-home col-6" style="text-align: right">-->
<!--                        <div class="card-input-home" style="text-align: right">-->
<!--                            <button type="button" @click.prevent="selectedItems">Cart</button>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </footer>-->
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';
import ProductCard from '@/views/user/ProductCard';
// import Currency from '@components/Currency';

export default {
    name: 'UserProductList',
    data () {
        return {
            prefix: urls.baseUrl,
            data: [],
            searchTerm: '',
            showCart: false,
            selectedProduct: [],
            bg_image: null
        };
    },
    mounted () {
        this.loadLogoImage();
        this.loadProduct();
    },
    methods: {
        loadLogoImage () {
            const component = this;
            axios.form(urls.logo, {}).then(function (data) {
                component.bg_image = data.data.image;
            });
        },
        loadProduct () {
            const component = this;
            axios.form(urls.user.ProductList, {}).then(function (data) {
                component.data = data.data.data;
            });
        },
        selectedItems () {
            const that = this;
            this.selectedProduct = that.data.filter(function (item) {
                return item.count > 0;
            });
            if (that.selectedProduct.length > 0) {
                this.showCart = !this.showCart;
            } else {
                alert('Please add Products to cart');
            }
        },
        showHome () {
            this.showCart = false;
        }
    },
    computed: {
        filteredData () {
            const vm = this;
            const searchTerm = vm.searchTerm.toUpperCase();

            if (searchTerm === '') {
                return vm.data;
            } else {
                return vm.data.filter(function (item) {
                    return item.title.toUpperCase().indexOf(searchTerm) >= 0;
                });
            }
        },

        selectedCount () {
            let count = 0;
            this.data.forEach(function (item) {
                count += item.count;
            });

            return count;
        },

        selectedPrice () {
            let total = 0;
            this.data.forEach(function (item) {
                total += (item.count * item.price);
            });

            return total;
        }
    },
    components: {
        ProductCard
        // Currency
    }
};

</script>

<style lang="scss">
    @import url('https://fonts.googleapis.com/css?family=Muli:400,400i,700,700i');

    body {
        font-family: 'Muli', sans-serif;
        /*background: #dddddd;*/
        background-image: url('../../assets/img/cart.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
    }

    .container {
        /*max-width: 600px;*/
        /*margin: 0 auto;*/
    }

    .item {
        width: 48%;
        float: left;
        margin-right: 1%;
        margin-left: 1%;
        position: relative;
    }

    a {
        text-decoration: none;
    }

    .search-box {
        .input {
            margin-top: 15px;
            margin-bottom: 15px;
            border-radius: 15px;
            padding: 10px;
            outline: 0;
            border: 1px solid #ccc;
            flex: 1;

        }
    }

    /* List animation */
    .list-enter-active,
    .list-leave-active,
    .list-move {
        transition: 300ms;
        transition-property: opacity, transform;
    }

    .list-enter {
        opacity: 0;
        transform: scaleY(0.7);
    }

    .list-enter-to {
        opacity: 1;
        transform: scaleY(1);
    }

    .list-leave-active {
        //position: absolute;
        //width: 100%;
        transform: scaleY(0.7);
    }

    .list-leave-to {
        opacity: 0;
        transform: scaleY(0);
        transform-origin: center top;
    }

    button[type=button] {
        width: 100%;
        background-color: #fbb72c;
        color: white;
        padding: 7px 20px;
        margin: 0 0;
        border: none;
        cursor: pointer;
        border-radius: 4px;
    }

    .card-input-home {
        margin: 0;
        padding: 00px;
        float: right;

    }

    .main-home {
        width: 100%;
    }

    .info-box-home {
        margin: 0;
        display: inline-block;

    }

    .footer {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: #ffffff;
        color: white;
        text-align: center;
    }

    .scroll {

        padding: 4px;
        width: 100%;
        height: 66vh;
        overflow-x: hidden;
        overflow-y: auto;
        text-align: justify;

    }

    .scroll::-webkit-scrollbar {
        display: none;
    }

    .style {
        padding: 10px;
        background: white;
        border-radius: 10px;
        margin-bottom: 25px;
    }

    @media only screen and (max-width: 552px) {
        .product-card .product-details .product-bottom-details .product-price {
            font-size: 12px;
        }
        .product-card .product-details .product-bottom-details .product-links a {
            margin-left: 0;
        }
        .product-card .product-details .product-bottom-details .product-links {
            text-align: unset;
            margin-top: 10px;
        }
        .product-card .product-details .product-bottom-details div {
            float: left;
            width: 100%;
        }

        .main-home {
            position: fixed;
            bottom: 0;
            width: 85%;
            left: 27px;
        }

        .container {
            width: 90%;
        }

    }

</style>
